import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import bgHero from "../images/background/holding_bg_darkened__purple_2000.jpg"

import styled, { withTheme, ThemeContext } from "styled-components"

const ContactWrapper = styled.section`
  background: #44337a;
  background: ${props => props.theme.purple};
  background-image: url(${bgHero});
  background-size: cover;
  background-position: center bottom;
  padding: 40px 0;
  .inner-container {
    display: flex;
    justify-content: center;
  }

  .email-line {
    text-align: center;

    a {
      display: inline-block;
      border-top: 1px dashed #fff;
      padding-top: 10px;
      color: #fff;
      text-decoration: none;
    }
  }
`

const ContactForm = styled.form`
  max-width: 400px;
  width: 100%;
  border-radius: 10px;

  label {
    font-size: 12px;
    display: block;
    width: 100%;
    color: #f8fafc;
  }
  span {
    margin-bottom: 2px;
    margin-top: 10px;
    display: block;
    line-height: 16px;
  }
  input,
  textarea {
    font-size: 16px;
    display: block;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    outline: 0;
    padding: 10px;
    box-shadow: 0;
    border: none;
    border-radius: 2px;
    font-weight: 200;
    color: #f8fafc;
  }
  textarea {
    height: 150px;
  }
  button {
    margin: 20px auto 0 auto;
    display: block;
    text-decoration: none;
    border: none;
    background: ${props => props.theme.blue};
    color: #f8fafc;
    padding: 8px 20px;
    border-radius: 2px;
    cursor: pointer;
    &:hover {
      background: #009b9b;
    }
  }
`

const ContactBlock = () => {
  const [values, setValues] = useState({})
  const handleInputChange = event => {
    event.persist()

    //const target = event.target
    //const value = target.value
    //const name = target.name

    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
  }

  return (
    <ContactWrapper>
      <h3 style={{ textAlign: "center", color: "#fff" }}>Contact Logged In</h3>
      <div className="inner-container">
        <ContactForm
          data-netlify="true"
          method="post"
          action=""
          name="contact"
          className="global-box-shadow"
        >
          <input type="hidden" name="form-name" value="contact" />

          <label>
            <span>Name*</span>
            <input
              type="text"
              name="name"
              onChange={handleInputChange}
              value={values.name}
              required
            />
          </label>
          <label>
            <span>Email*</span>
            <input
              type="text"
              name="email"
              onChange={handleInputChange}
              value={values.email}
              required
            />
          </label>
          <label>
            <span>Phone No.</span>
            <input
              type="text"
              name="phone"
              onChange={handleInputChange}
              value={values.phone}
            />
          </label>
          <label>
            <span>Message</span>
            <textarea
              name="message"
              onChange={handleInputChange}
              value={values.message}
              required
            />
          </label>
          <div className="global-btn-wrapper">
            <button className="global-btn global-btn_blue" type="submit">
              Submit
            </button>
          </div>
        </ContactForm>
      </div>
      <div className="email-line">
        <a href="mailto:info@loggedin.co.uk">info@loggedin.co.uk</a>
      </div>
    </ContactWrapper>
  )
}

export default ContactBlock
