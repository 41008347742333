import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ContactBlock from "../components/contact_block"
import styled, { withTheme, ThemeContext } from "styled-components"
import Icon from "../utilities/icons"
import BlueCheck from "../images/icons/check_blue.svg"

const Tagline = styled.h3`
  @media (max-width: 880px) {
    text-align: center;
  }
`

const BaseCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 880px) {
    flex-direction: column;
  }
`

const BaseCard = styled.div`
  width: 100%;
  background: #f7fafc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin: 0 20px;
  border-radius: 5px;
  overflow: hidden;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }

  @media (max-width: 880px) {
    margin: 0 0 20px 0;
  }

  h4 {
    background: #fff;
    padding: 20px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #4a5568;

    span {
      margin-right: 5px;
    }
  }

  ul {
    padding: 20px;
    margin: 0;

    li {
      padding: 0 0 0 28px;
      background-image: url(${BlueCheck});
      background-size: 18px;
      background-position: top 5px left;
      background-repeat: no-repeat;
      list-style-type: none;
      font-weight: 600;
      font-size: 0.9rem;
    }
  }

  .horizontal-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .horizontal-list-item {
    padding: 20px 40px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;

    @media (max-width: 880px) {
      width: 50%;
    }
  }

  .horizontal-list-item-standout {
    display: inline-block;
    font-weight: 900;
    font-size: 2rem;
    margin-bottom: 5px;
    color: ${props => props.theme.purple};
  }
`

const IconBG = styled.div`
  background: ${props => props.theme.blue};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

const LandingSection = styled.section`
  margin: 20px 0;
  padding: 40px 0;
  border-top: 1px solid #d9d9d9;

  &:first-of-type {
    /*border-top: none;*/
  }

  @media (max-width: 880px) {
    padding: 20px 0;
  }

  h4 {
    @media (max-width: 880px) {
      text-align: center;
    }
  }
`

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home | Logged In | Tech Solutions | Newark"
      description="Tech Solutions from Logged In, Offering a range of tech solutions across the UK from our Newark on Trent office."
    />
    <div className="content-area">
      <div className="inner-container">
        <Tagline style={{}}>
          All of our propositions follow a simple 3 step approach:
        </Tagline>

        <LandingSection>
          <h4>1. A range of Hardware, Connectivity and Apps</h4>
          <BaseCardWrapper>
            <BaseCard>
              <h4>
                <span>Devices</span>
                <Icon name="laptop" width="20px" color="#4A5568" />
              </h4>
              <ul>
                <li>PC/notebook</li>
                <li>Smart phone</li>
                <li>Tablets</li>
                <li>Desk phones</li>
                <li>Printing </li>
                <li>Intelligent paging</li>
              </ul>
            </BaseCard>

            <BaseCard>
              <h4>
                <span>Connectivity</span>
                <Icon name="connectivity" width="20px" color="#4A5568" />
              </h4>
              <ul>
                <li>Superfast internet</li>
                <li>Dedicated fibre internet</li>
                <li>Unlimited 5G mobile plans</li>
                <li>Fixed line plans</li>
                <li>Cloud telephony systems</li>
                <li>ioT - analytics & tracking</li>
              </ul>
            </BaseCard>

            <BaseCard>
              <h4>
                <span>Software / Applications</span>
                <Icon name="cloud_cycle" width="20px" color="#4A5568" />
              </h4>
              <ul>
                <li>Office 365 / Google Apps</li>
                <li>E-mail and Calendar</li>
                <li>Cyber Security &amp; Antivirus </li>
                <li>Online storage &amp; backup</li>
                <li>Cloud Phone System</li>
                <li>Web Design &amp; SEO</li>
              </ul>
            </BaseCard>
          </BaseCardWrapper>
        </LandingSection>

        <LandingSection>
          <h4>2. A Choice of Contract Terms</h4>

          <BaseCard>
            <h4>
              <span>Contractual Terms</span>
              <Icon name="calendar" width="20px" color="#4A5568" />
            </h4>
            <div className="horizontal-list">
              <div className="horizontal-list-item">
                <span className="horizontal-list-item-standout">30</span> / Days
              </div>
              <div className="horizontal-list-item">
                <span className="horizontal-list-item-standout">12</span> /
                Months
              </div>
              <div className="horizontal-list-item">
                <span className="horizontal-list-item-standout">24</span> /
                Months
              </div>
              <div className="horizontal-list-item">
                <span className="horizontal-list-item-standout">36</span> /
                Months
              </div>
            </div>
          </BaseCard>
        </LandingSection>

        <LandingSection>
          <h4>3. Installation, Support and Optional Extras</h4>

          <BaseCardWrapper>
            <BaseCard>
              <h4>
                <span>Installation</span>
                <Icon name="install" width="20px" color="#4A5568" />
              </h4>
              <ul>
                <li>Dedicated on-site installation service</li>
                <li>Device Pre Staging </li>
                <li>Remote Installation</li>
                <li>Self Install</li>
                <li>Data Cabling Services</li>
              </ul>
            </BaseCard>

            <BaseCard>
              <h4>
                <span>Support Packages</span>
                <Icon
                  name="headset"
                  width="24px"
                  height="24px"
                  color="#4A5568"
                  style={{ transform: "scale(0.8)" }}
                />
              </h4>
              <ul>
                <li>Fully Managed</li>
                <li>Pay As You Go</li>
                <li>Remote Support</li>
                <li>Short Term & Loan Device Solutions </li>
                <li>Consultancy and Advisory</li>
              </ul>
            </BaseCard>
            <BaseCard>
              <h4>
                <span>Extras</span>
                <Icon name="extra_clipboard" width="20px" color="#4A5568" />
              </h4>
              <ul>
                <li>Next business day replacement repair</li>
                <li>Device Insurance</li>
                <li>Disaster Recovery</li>
                <li>Accessories</li>
              </ul>
            </BaseCard>
          </BaseCardWrapper>
        </LandingSection>
      </div>
    </div>
    <ContactBlock />
  </Layout>
)

export default IndexPage
